import React from 'react'
import styled from 'styled-components'

const redirecturl = 'https://code.astn.me/proxy/3010/v1/login/redirect'

// Geist
import { Text, Button, Modal, useToasts, Divider } from '@geist-ui/react'

export default function Landing() {
  const [, setToast] = useToasts()
  const [open, setOpen] = React.useState(false)
  const [fundInfo, setFundinfo] = React.useState(false)

  //  <Button auto type="secondary" style={{marginRight: 10}} onClick={() => setOpen(true)}>Trade</Button>
  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Title style={{fontWeight: 600}}>Login</Modal.Title>
        <Modal.Content style={{textAlign: 'center'}}>
          <a href={redirecturl}>
            <Button auto type="success">Sign in with Google</Button>
          </a>
        </Modal.Content>
      </Modal>

      <Modal open={fundInfo} onClose={() => setFundinfo(false)}>
        <Modal.Title style={{fontWeight: 600}}>Fund Nothing Ventures</Modal.Title>
        <Modal.Content style={{textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
          Nothing Ventures is a modern experimental Venture Capitalist Firm that utilizes our own decentralized Cryptocurrency network to subsidize small strategically picked institutions with enormous growth potential. The NTH team has made significant advancements and is in need of funding for our final push to market. By becoming one of our early investors, you will receive a stake in our firm and additional benefits such as early access to the NTH Blockchain.

          <Button auto type="secondary-light" style={{marginTop: 20, marginBottom: -25}} onClick={() => window.location.href = 'https://commerce.coinbase.com/checkout/8d39fc1e-0d16-4998-8ca2-085c3d560482'}>Fund with Coinbase</Button>
        </Modal.Content>
        <Modal.Content style={{textAlign: 'center', fontSize: 12, marginBottom: -10, display: 'flex', flexDirection: 'column'}}>
          <Text style={{marginBottom: -10}}>or <a href="mailto:support@nth.so">Contact us</a></Text>
          <Divider />
          <Text style={{marginTop: -10}}>All investments involve risks, including the possible loss of capital.</Text>
        </Modal.Content>
      </Modal>
      <Text h1 style={{textAlign: 'center'}}>Nothing Ventures</Text>
      <Row>
        <Button auto type="secondary" style={{marginRight: 10}} onClick={() => setToast({text: 'Coming Soon'})}>Trade</Button>
        <Button auto type="success" ghost onClick={() => setFundinfo(true)}>Fund</Button>
      </Row>
      <Text p style={{fontStyle: 'italic', textAlign: 'center'}}>“There’s no nobility in poverty.” - Jordan Belfort</Text>
    </>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`