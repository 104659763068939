import React from 'react'
import styled from 'styled-components'

// Components
import Landing from './components/Landing'
import NetworkStats from './components/NetworkStats'

// Geist
import { Page, Text } from '@geist-ui/react'

export default function App() {
  return (
    <Page dotBackdrop>
    <Container>
      <NetworkStats />
      <Landing />
      <FootContainer>
        <Text>© 2021 The Nothing Developers | <a href="mailto:support@nth.so">Contact</a></Text>
      </FootContainer>
    </Container>
    </Page>
  )
}

const Container = styled.div`
  min-height: 50vh;
  margin: 20px;
  padding: 10px;
  display: flex;
  border-radius: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const FootContainer = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: rgb(0, 0, 0);
  text-align: center;
  font-weight: 550;
`