import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect';

// Geist
import { Tag } from '@geist-ui/react'

export default function NetworkStats() {
  if (isMobile) return null;
  return (
    <Container>
      <Tag>nth</Tag>
    </Container>
  )
}

const Container = styled.div`
position: fixed;
bottom: 0;
left: 0;
margin: 15px;
`